import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import { FaBeer } from 'react-icons/fa';
import React, { useEffect, useState } from 'react'
import { BsCalendarEvent } from 'react-icons/bs';
import { BiTimeFive } from 'react-icons/bi';
import { GiCarSeat } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { MdLocationPin, MdOutlineLocationOn } from 'react-icons/md';
import { TbLocationFilled } from 'react-icons/tb';
import { getViajePorId } from '../../database';
import { FaRegCircleStop } from 'react-icons/fa6';

const CardViajes = ({ viaje }) => {
    const { origen, destino, asientos_disponibles, fecha, hora, id, id_chofer, precio } = viaje;
    const [paradas, setParadas] = useState();
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    const navigate = useNavigate();

    const irViaje = () => {
        navigate('/viaje/' + id);
    }

    const obtenerElementoEnPosicion1 = (texto) => {
        const arreglo = texto.split(',');
        if (arreglo.length >= 2) {
            return arreglo[1].trim();
        } else {
            return null;
        }
    };

    const obtenerViajeCompleto = async () => {
        const resultadoViaje = await getViajePorId(id);

        if (resultadoViaje.success) {
            setParadas(resultadoViaje.paradas);
        }
    }

    useEffect(() => {
        obtenerViajeCompleto();
    }, []);

    const convertirFecha = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000); // Convertir segundos a milisegundos
            return date.toLocaleDateString(); // O usa toLocaleString() si necesitas la hora también
        }
        return '';
    };

    console.log(paradas)

    return (
        <Box backgroundColor="#f0f0f0" p="5px" boxShadow="2px 2px 5px 0px rgba(30,30,30,0.25)" borderRadius="5px" display="flex" justifyContent="center" cursor="pointer" onClick={irViaje}>
            <Box w="95%">
                <Flex direction="column">
                    <Flex>
                        <Flex alignItems="center" gap="5px">
                            <TbLocationFilled />
                            <Text fontWeight="bold">{obtenerElementoEnPosicion1(origen)}  - ${precio}</Text>
                        </Flex>
                    </Flex>
                    <Spacer />
                    {paradas && paradas.map((parada, index) => (
                        <Box key={index} ml="10px" w="100%" display="flex" flexDirection="row" alignItems="center" gap="5px">
                            <Box>
                                <FaRegCircleStop color="#adacac" />
                            </Box>
                            <Box>
                                <Text maxW="100%" isTruncated mt="-5px">{obtenerElementoEnPosicion1(parada.ciudad)} - ${parada.precio}</Text>
                            </Box>
                        </Box>
                    ))}
                    <Spacer />
                    <Flex alignItems="center" gap="5px">
                        <MdOutlineLocationOn />
                        <Text fontWeight="medium">{obtenerElementoEnPosicion1(destino)}</Text>
                    </Flex>
                </Flex>
                <Flex mt="5px">
                    <Box display="flex" justifyContent="center" alignItems="center" gap="5px">
                        <BsCalendarEvent />
                        <Text>{convertirFecha(fecha)}</Text>
                    </Box>
                    <Spacer />
                    <Box display="flex" justifyContent="center" alignItems="center" gap="5px">
                        <BiTimeFive />
                        <Text>{hora}</Text>
                    </Box>
                    <Spacer />
                    <Box display="flex" justifyContent="center" alignItems="center" gap="5px">
                        <GiCarSeat />
                        <Text>{asientos_disponibles}</Text>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

export default CardViajes;
